<template>
	<div class="px-4 w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<div class="mb-3 dF aC" style="font-size: 24px; gap: 20px; justify-content: space-between" v-if="selectedPromoCode">
			<div>
				<a-icon type="arrow-left" @click="$router.go(-1)" />
				<strong class="ml-3">{{ selectedPromoCode.code }}</strong>
			</div>

			<div class="dF" style="gap: 20px">
				<a-button @click="emailModal = true">EMAIL</a-button>
				<a-button @click="publishModal = true">{{
					selectedPromoCode.published_at ? "UNPUBLISH" : "PUBLISH"
				}}</a-button>
				<a-button @click="deleteModal = true" type="danger" ghost>
					<a-icon type="delete" /> DELETE</a-button>
			</div>
		</div>
		<bh-loading :show="loading" />
		<div class="profile mt-2">
			<a-card>
				<div class="dF aC" style="gap: 20px;">
					<div class="text-dark text-md">
						<strong>Promo Code Details</strong>
						<a-tooltip overlayClassName="add-promo-code-tooltip" class="ml-2">
							<template #title>
								<div class="text-md">
									Promo Code Descriptions:
								</div>
								<br />
								<div>
									<strong>FREEINSTANCE</strong>: Provides a full discount on everything, including the
									base
									plan/license fee and any add-ons. Will have to be manually removed.
								</div>
								<br />
								<div>
									<strong>FREEPLAN</strong>: Applies to the subtotal. Discounts can be set as either a
									percentage or a fixed dollar amount. Must be offered on its own.
									If two or more services are being offered they must be grouped.
								</div>
								<br />
								<div>
									<strong>ADD-ONS</strong>: Applies to individual add-ons only and cannot be combined
									with
									other discounts. This can be set as either a percentage or a fixed dollar value.
								</div>
								<br />
								<div>
									<strong>Note</strong>: When creating new promo codes, you can set specific start and
									end
									dates to control the time frame of the promotion.
								</div>
							</template>
							<a-icon type="question-circle" style="font-size: 14px" />
						</a-tooltip>
					</div>
					<span class="button" v-if="promoCode.isExpired" style="background-color: #dc3545">EXPIRED</span>
					<span class="button" v-else-if="promoCode.published_at"
						style="background-color: #28c791">ACTIVE</span>
					<span v-else class="button" style="background-color: #ffc107">
						DRAFT
					</span>
				</div>

				<hr style="margin-left: -24px; margin-right: -24px" />

				<a-form-model ref="ruleForm" :model="promoCode">
					<a-row :gutter="16">
						<a-col :span="$mq == 'sm' ? 24 : 8">
							<a-form-model-item ref="name" label="Promo Code Name" prop="name" required>
								<a-input size="large" v-model="promoCode.name"
									placeholder="Please Enter Promo Code Name" required />
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq == 'sm' ? 24 : 16">
							<a-form-model-item ref="description" label="Promo Code Description" prop="description">
								<a-textarea size="large" v-model="promoCode.description"
									placeholder="Please Enter Promo Code Description"
									:auto-size="{ minRows: 2, maxRows: 5 }" />
							</a-form-model-item>
						</a-col>
					</a-row>
					<a-row :gutter="16">
						<a-col :span="$mq == 'sm' ? 24 : 16">
							<a-form-model-item prop="code" label="Promo Code">
								<a-row :gutter="16">
									<a-col :span="$mq == 'sm' ? 24 : 12">
										<a-input v-model="promoCode.code" placeholder="Enter Promo Code" size="large"
											:disabled="promoCode.autogenerate">
										</a-input>
									</a-col>
									<a-col :span="$mq == 'sm' ? 24 : 12">
										<a-checkbox v-model="promoCode.autogenerate" @change="promoCode.code = ''">
											Auto Generate Promo Code
										</a-checkbox>
									</a-col>
								</a-row>
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq == 'sm' ? 24 : 8">
							<a-form-model-item prop="appliesTo" label="Promo Code for">
								<a-select v-model="promoCode.appliesTo" size="large">
									<a-select-option value="user">
										Customer
									</a-select-option>
									<a-select-option value="partner">
										Partner
									</a-select-option>
								</a-select>
							</a-form-model-item>
						</a-col>
					</a-row>
					<a-row :gutter="16">
						<a-col :span="$mq == 'sm' ? 24 : 8">
							<a-form-model-item prop="type" label="Promo Code Type">
								<a-select v-model="promoCode.type" size="large">
									<a-select-option value="percentage">
										Percentage
									</a-select-option>
									<a-select-option value="amount">
										Amount
									</a-select-option>
									<a-select-option value="packageExtra">
										Add On
									</a-select-option>
								</a-select>
							</a-form-model-item>
						</a-col>
						<a-col v-if="promoCode.type === 'packageExtra'" :span="$mq == 'sm' ? 24 : 8">
							<a-form-model-item prop="package_extras" label="AddOn Type">
								<a-select v-model="promoCode.package_extras" size="large">
									<a-select-option v-for="(pkg, index) in packageExtras" :key="index" :value="pkg.id">
										{{ pkg.name }}
									</a-select-option>
								</a-select>
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq == 'sm' ? 24 : 8">
							<a-form-model-item ref="value" :label="promoCodeValueLabel" prop="value" required>
								<a-input-number style="width: 300px; max-width: 100%" size="large" :min="1"
									v-model="promoCode.value" placeholder="Please Enter value"
									:formatter="formatterValue"
									:disabled="selectedAddOnType && ['onboarding', 'sms'].includes(selectedAddOnType.identifier)" />
							</a-form-model-item>
						</a-col>
					</a-row>
					<a-row :gutter="16">
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Start DateTime" prop="startDate" :rules="req(
								'Please enter the Promo Code Start DateTime'
							)
								">
								<a-date-picker v-model="promoCode.startDate" placeholder="Promo Code Start DateTime"
									:showTime="true" size="large" required style="width: 100%">
								</a-date-picker>
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="End DateTime" prop="endDate" :rules="req(
								'Please enter the Promo Code End DateTime'
							)
								">
								<a-date-picker :disabled-date="disabledDate" v-model="promoCode.endDate"
									placeholder="Promo Code End DateTime" :showTime="true" size="large" required
									style="width: 100%">
								</a-date-picker>
							</a-form-model-item>
						</a-col>
					</a-row>
					<a-row :gutter="16">
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item ref="usageLimits" prop="usageLimits">
								<template slot="label">
									Usage Limit
									<a-tooltip overlayClassName="change-tooltip-color"
										title="Number of times promo code can be used for a project.">
										<a-icon type="question-circle" style="font-size: 14px" />
									</a-tooltip>
								</template>
								<a-input-number style="width: 500px; max-width: 100%" size="large"
									v-model="promoCode.usageLimits" placeholder="Please Enter Usage Limits" />
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-item label="Single Use">
								<a-switch v-model="promoCode.singleUse" checked-children="On"
									un-checked-children="Off" />
							</a-form-item>
						</a-col>
					</a-row>
				</a-form-model>

				<div class="dF jE w-full" style="gap: 20px">
					<a-button @click="$router.go(-1)">CANCEL</a-button>
					<a-button type="primary" @click="updatePromoCode">SAVE</a-button>
				</div>
			</a-card>
		</div>
		<a-modal v-model="publishModal" :title="`${selectedPromoCode.published_at ? 'Unpublish' : 'Publish'
			} Promo Code`" :ok-text="`${selectedPromoCode.published_at ? 'Unpublish' : 'Publish'
				}`" @ok="updatePromoCodeState" :centered="true">
			<p>
				Are you sure you want to
				{{ selectedPromoCode.published_at ? "Unpublish" : "Publish" }}
				this promo code
				<strong>
					{{ selectedPromoCode.code }}
				</strong>
				?
			</p>
		</a-modal>
		<a-modal v-model="deleteModal" title="Delete Promo Code" ok-text="Delete" @ok="deletePromoCode"
			:centered="true">
			<p>
				Are you sure you want to delete this promo code
				<strong>
					{{ selectedPromoCode.code }}
				</strong>
				?
			</p>
		</a-modal>
		<a-modal v-model="emailModal" title="Email Promo Code" ok-text="SEND" @ok="emailPromoCode" :centered="true">
			<a-form-model-item label="Recipient(s)" prop="recipients">
				<a-select mode="multiple" show-search placeholder="Choose a Recipient(s)" v-model="recipients"
					:filter-option="filterOption" option-filter-prop="email" size="large" style="width: 100%">
					<a-select-option v-for="user in allUsers" :key="user.email" :value="user.email">
						{{ user.email }}
					</a-select-option>
				</a-select>
			</a-form-model-item>
		</a-modal>
	</div>
</template>

<script>
	import bhLoading from "bh-mod/components/common/Loading";
	import moment from "moment";
	import { mapMutations } from "vuex";

	export default {
		components: {
			bhLoading,
		},
		data: () => {
			return {
				loading: false,
				emailModal: false,
				publishModal: false,
				deleteModal: false,
				recipients: [],
				search: "",
				promoCode: {
					appliesTo: "user",
					autogenerate: false,
					name: "",
					description: "",
					code: "",
					type: "percentage",
					package_extras: null,
					value: 0,
					startDate: "",
					endDate: "",
					usageLimits: 0,
					singleUse: false,
				},
				labels: {
					"api_calls": "Free Months",
					"onboarding": "Free Quantity",
					"lot_door_fee": "Free Quantity",
					"online_video_chat_support": "Free Months",
					"sms": "Free Quantity"
				}
			};
		},
		computed: {
			selectedPromoCode() {
				return this.$store.state.selectedPromoCode;
			},

			allUsers() {
				return this.$store.state.allUsers;
			},

			packageExtras() {
				return this.$store.state.packageExtras || [];
			},

			selectedAddOnType() {
				if (this.promoCode.type === 'packageExtra') {
					let found = this.packageExtras.find(p => p.id === this.promoCode.package_extras);
					return found
				}
				return null
			},

			promoCodeValueLabel() {
				let label = 'Value';

				if (this.selectedAddOnType && this.labels[this.selectedAddOnType.identifier]) {
					label = this.labels[this.selectedAddOnType.identifier]
				}
				return label;
			}
		},
		watch: {
			selectedAddOnType(value) {
				if (value && ['onboarding', 'sms'].includes(value.identifier)) {
					this.promoCode.value = 1
				}
			}
		},
		created() {
			if (!this.selectedPromoCode) {
				return this.$router.push('/');
			}

			this.promoCode = {
				...this.selectedPromoCode,
				package_extras: this.selectedPromoCode.package_extras && this.selectedPromoCode.package_extras[0]
					? this.selectedPromoCode.package_extras[0].id : this.packageExtras.length ? this.packageExtras[0].id : null,
				startDate: moment(this.selectedPromoCode.startDate),
				endDate: moment(this.selectedPromoCode.endDate)
			};
		},
		methods: {
			...mapMutations(["SET_PROMO_CODE"]),

			req: (msg) => ({ required: true, message: msg }),

			filterOption(input, option) {
				return (
					option.componentOptions.children[0].text
						.toLowerCase()
						.indexOf(input.toLowerCase()) >= 0
				);
			},

			formatterValue(value) {
				if (this.promoCode.type === "percentage") {
					return value + " %";
				} else if (this.promoCode.type === "amount") {
					return "$ " + value;
				}
				return value;
			},

			disabledDate(current) {
				if (!this.promoCode.startDate) {
					return;
				}
				// Can not select days before start date
				return (
					current &&
					current < moment(this.promoCode.startDate).endOf("day")
				);
			},

			updatePromoCode() {
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						if (!this.promoCode.code && !this.promoCode.autogenerate) {
							return this.$message.error(
								"Please Enter Promo Code or select Auto Generate Promo Code."
							);
						}
						if (!this.promoCode.value) {
							return this.$message.error(
								"Please Enter valid number for value."
							);
						}
						if (
							this.promoCode.type === "packageExtra" &&
							!this.promoCode.package_extras
						) {
							return this.$message.error(
								"Please select the AddOn Type."
							);
						}
						const startDateTime = +moment(
							this.promoCode.startDate
						).format("x");
						const endDateTime = +moment(this.promoCode.endDate).format(
							"x"
						);
						if (startDateTime > endDateTime) {
							return this.$message.error(
								"Start DateTime can't be after End DateTime. Please Enter valid Start DateTime."
							);
						}
						if (
							!this.promoCode.usageLimits &&
							!this.promoCode.singleUse
						) {
							return this.$message.error(
								"Please Enter Usage Limit or turn on Single Use."
							);
						}
						const promoCodeObj = {
							...this.promoCode,
							package_extras:
								this.promoCode.type === "packageExtra"
									? [this.promoCode.package_extras]
									: null,
						};
						this.loading = true;
						this.updateAPICall(promoCodeObj);
					} else {
						console.error("Invalid form details");
						return false;
					}
				});
			},

			async updatePromoCodeState() {
				const promoCodeObj = {
					published_at: this.selectedPromoCode.published_at ? null : new Date()
				};
				await this.updateAPICall(promoCodeObj);

				this.publishModal = false;
			},

			async updateAPICall(promoCodeObj) {
				try {
					let { data } = await this.$api.put(`/promo-codes/${this.selectedPromoCode.id}`, promoCodeObj);
					this.loading = false;

					this.$message.success("Promo Code updated successfully!");
					this.SET_PROMO_CODE(data);
					this.$router.push("/promoCode");
				} catch (err) {
					this.loading = false;
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, "Error while updating Promo Code. Please try again!"))
					}
				}
			},

			async emailPromoCode() {
				if (!this.recipients.length) {
					return this.$message.warn("Please select recipients first");
				}
				try {
					await this.$api.post(`/promo-codes/${this.selectedPromoCode.id}/email`, { recipients: this.recipients });
					this.$message.success("Promo Code send successfully to selected emails!");
					this.recipients = [];
					this.emailModal = false;
				} catch (err) {
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, "Error while promo code to selected emails. Please try again!"))
					}
				}
			},

			async deletePromoCode() {
				try {
					await this.$api.delete(`/promo-codes/${this.selectedPromoCode.id}`);
					this.deleteModal = false;
					this.SET_PROMO_CODE({});
					this.$router.push("/promoCode");
				} catch (err) {
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, "Error while deleting promo code. Please try again!"))
					}
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.profile-card {
		text-align: center;
	}

	.button {
		color: #fff;
		border-radius: 4px;
		font-size: 14px;
		text-align: center;
		padding: 4px 10px;
	}
</style>
